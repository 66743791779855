import 'ol/ol.css';
import './App.css';
import React from 'react';
import Main from './Main';
import { CssBaseline } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ErrorBoundary from './ErrorBoundary';
import Bundle from './resources/Bundle';
import { de } from './resources';
import { makeFactory, MessagesProvider } from '@moss-ui/react-i18n';
import RequestError from './RequestError';
import { Config } from './useConfig/interface';
import ConfigProvider from './ConfigProvider';
import { useAsync } from '@moss-ui/react-hooks';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0074b8',
    },
    secondary: {
      main: '#05b035',
    },
  },
});

const factory = makeFactory<Bundle>({ de }, 'de');

const App: React.FC = () => {

  const { value: config } = useAsync<Config>(async () => {
    const response = await fetch('/config.json');
    return response.json();
  });

  return (
    <ConfigProvider config={config}>
      <MessagesProvider factory={factory}>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="App">
              <ErrorBoundary errorComponent={(error, reset) => <RequestError error={error} onRetry={reset} />}>
                <Main />
              </ErrorBoundary>
            </div>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </MessagesProvider>
    </ConfigProvider>
  )
};

export default App;
